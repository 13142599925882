import { Button as MuiButton, styled } from '@mui/material';
import { motion } from 'framer-motion';
import React from 'react';

import {
  largeSizeStyles,
  mediumSizeStyles,
  SizeStylesType,
  smallSizeStyles
} from './Button.constant';
import { ColorVariant } from './Button.types';

function getSizeStyles(size: string) {
  if (size === 'lg') return largeSizeStyles;
  if (size === 'sm') return smallSizeStyles;
  return mediumSizeStyles;
}
const PrimaryButton = styled(MuiButton)<{ sizeStyles?: SizeStylesType }>(({ sizeStyles }) => ({
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#2407D8'
  },
  fontWeight: 600,
  backgroundColor: '#5034ff !important',
  ...sizeStyles
}));

const SecondaryButton = styled(MuiButton)<{ sizeStyles?: SizeStylesType }>(({ sizeStyles }) => ({
  color: '#181743',
  '&:hover': {
    backgroundColor: '#181743',
    color: '#FFFFFF'
  },
  fontWeight: 600,
  backgroundColor: '#FFFFFF',
  border: '2px solid #181743',
  ...sizeStyles
}));

const TertiaryButton = styled(MuiButton)<{ sizeStyles?: SizeStylesType }>(({ sizeStyles }) => ({
  color: '#2407D8',
  '&:hover': {
    backgroundColor: '#BEBDFF',
    color: '#2407D8'
  },
  fontWeight: 600,
  backgroundColor: '#DFE8FF',
  ...sizeStyles
}));

const GenericButton = styled(MuiButton)<{ sizeStyles?: SizeStylesType }>(({ sizeStyles }) => ({
  color: '#5034FF',
  '&:hover': {
    color: '#2407D8',
    backgroundColor: 'transparent'
  },
  fontWeight: 600,
  ...sizeStyles
}));

export default function Button({ size = 'sm', variant = 'primary', ...props }) {
  const { children } = props;
  const sizeStyles = getSizeStyles(size);

  return (
    {
      [ColorVariant.PRIMARY]: (
        <motion.div>
          <PrimaryButton {...props} sizeStyles={sizeStyles}>
            <div className="p-6">{children}</div>
          </PrimaryButton>
        </motion.div>
      ),
      [ColorVariant.SECONDARY]: (
        <motion.div>
          <SecondaryButton {...props} sizeStyles={sizeStyles}>
            {children}
          </SecondaryButton>
        </motion.div>
      ),
      [ColorVariant.TERTIARY]: (
        <motion.div>
          <TertiaryButton {...props} sizeStyles={sizeStyles}>
            {children}
          </TertiaryButton>
        </motion.div>
      ),
      [ColorVariant.GENERIC]: (
        <motion.div>
          <GenericButton {...props} sizeStyles={sizeStyles}>
            {children}
          </GenericButton>
        </motion.div>
      )
    }[variant] || <></>
  );
}
