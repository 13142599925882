import axios from 'axios';
import { RoutePath } from 'routes';
import { refreshResponseType } from 'types/User';
import { HttpResponse } from 'utils/error';

import { REFRESH_TOKEN_PATH } from 'constants/endpoints';

import CustomerAuth from './auth';

const client = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_ENDPOINT,
  timeout: 120000
});

// Request interceptor
client.interceptors.request.use(
  (request) => {
    const token = CustomerAuth.accessToken;
    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor
client.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    // If UnAuthorized error, reject and redirect to login
    if (
      error.response.status === HttpResponse.Unauthorised &&
      originalRequest.url === '/auth/token'
    ) {
      logoutUser();
      return Promise.reject(error);
    }

    //if access token expired
    if (error.response.status === HttpResponse.Unauthorised && !originalRequest.retry) {
      originalRequest.retry = true;
      return axios
        .post<refreshResponseType>(
          `${process.env.NEXT_PUBLIC_API_ENDPOINT}/${REFRESH_TOKEN_PATH}`,
          {},
          {
            headers: { Authorization: 'Bearer ' + CustomerAuth.refreshToken }
          }
        )
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            const { access_token, refresh_token } = res.data;

            CustomerAuth.setTokens({
              accessToken: access_token,
              refreshToken: refresh_token
            });
            client.defaults.headers.common.Authorization = `Bearer ${CustomerAuth.accessToken}`;
            return client(originalRequest);
          }
          if (res.status === 401) {
            logoutUser();
          }
          return Promise.reject(error);
        })
        .catch((err) => {
          logoutUser();
          return Promise.reject(err);
        });
    }

    return Promise.reject(error);
  }
);

export const logoutUser = () => {
  CustomerAuth.clearTokens();
  window?.history?.replaceState({}, '', RoutePath.login);
  window.location.reload();
};

export default client;
