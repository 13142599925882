/* eslint-disable react/jsx-no-useless-fragment */
// we might need fragments if there's someone is passing a component with multiple children and hasn't used a fragment there
import PropTypes from './propertyControlledComponent.types';

const PropertyControlledComponent = ({ controllerProperty, children, fallback }: PropTypes) => {
  if (!controllerProperty) {
    if (fallback) return <>{fallback}</>;
    return <></>;
  }

  return <>{children}</>;
};

export default PropertyControlledComponent;
