import { Button } from '@libs/atoms/Button';
import { ConfirmationModal } from '@libs/atoms/ConfirmationModal';
import PropertyControlledComponent from '@libs/atoms/PropertyControlledComponent';
import { AccountCircleOutlined, ArrowBack, Close, Download, Logout } from '@mui/icons-material';
import { Alert, CircularProgress, Modal, Paper, Snackbar, Tooltip } from '@mui/material';
import Header from 'components/Header-Sidebar/Header';
import ProjectModal from 'components/Newproject/ProjectModal';
import S3FileUploadComponent from 'components/Newproject/ProjectUpload/S3FileUploadComponent';
import useAuth from 'hooks/useAuth';
import { useProject } from 'hooks/useProject';
import { login_strings as strings } from 'i18n';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _get from 'lodash/get';
import _isEqual from 'lodash/isEqual';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RoutePath } from 'routes';
import { logoutUser } from 'services/apiClient';
import CustomerAuth from 'services/auth';
import { setProjectModal } from 'slices/ProjectSlice';
import { setStatus } from 'slices/StatusSlice';
import {
  useGetAnnotationProjectDetailsQuery,
  useGetTrainingJobDetailsQuery
} from 'store/api/slices/getApis/getApisSlice';
import { RootState } from 'store/AppStore';
import { CurrentDashBoardView } from 'types';
import { PROJECT_STATUS } from 'types/Project';

import styles from './Layout.module.scss';

export default function Layout({ children }) {
  const router = useRouter();
  const logOuthandler = () => {
    ConfirmationModal.show({
      visible: true,
      title: 'Logout!',
      bodyElement: (
        <div className="mb-4 flex w-full justify-center text-sm">
          Are you sure you want to Logout it?
        </div>
      ),
      onSubmitCb: () => logoutUser(),
      loading: false,
      submitBtnText: 'Yes, Logout'
    });
  };
  const { updateProjectStatus, downloadSRT, uploadMediav2, isUploading } = useProject();

  const [currentView, setCurrentView] = useState<CurrentDashBoardView>(
    CurrentDashBoardView.ANNONATION_PROJECT
  );
  const { pathname, push } = useRouter();

  const logo = useMemo(
    () => (
      <span className="ml-4 w-[200px] cursor-pointer	text-2xl" onClick={() => push('/dashboard')}>
        {strings.trainingApp}
      </span>
    ),
    [push]
  );
  const { deleteAccount } = useAuth();

  const dispatch = useDispatch();

  const newProjectHandler = useCallback(() => {
    dispatch(setProjectModal({ showProjectModal: true }));
  }, [dispatch]);

  const handleNewTrainingJob = useCallback(() => {
    router.push(`${RoutePath.training_job}/create-job`);
  }, [router]);

  const handleNewMynaProject = useCallback(() => {
    router.push(`${RoutePath.myna_project}/createMynaProject`);
  }, [router]);

  const handleDeleteUser = useCallback(() => {
    deleteAccount();
  }, [deleteAccount]);

  const user = useSelector((state: RootState) => state.user);

  const { data: project, refetch } = useGetAnnotationProjectDetailsQuery(
    router.query?.project_id as string,
    {
      skip: !router.query?.project_id,
      refetchOnMountOrArgChange: true
    }
  );

  const { data: trainingDetails } = useGetTrainingJobDetailsQuery(
    router.query?.training_id as string,
    {
      skip: !router.query?.training_id
    }
  );

  const handleViewChangeClick = useCallback(
    (view) => {
      setCurrentView(view);
      if (view != CurrentDashBoardView.ANNONATION_PROJECT) {
        router.push(`/${view}`);
      } else {
        router.push(`${RoutePath.dashboard}`);
      }
    },
    [router]
  );

  useEffect(() => {
    let view = CurrentDashBoardView.ANNONATION_PROJECT;
    if (router.pathname === RoutePath.dashboard || router.pathname === '/')
      view = CurrentDashBoardView.ANNONATION_PROJECT;
    else if (
      router.pathname === RoutePath.training_job ||
      router.pathname === RoutePath.create_job ||
      router.pathname === RoutePath.current_job
    )
      view = CurrentDashBoardView.TRAINING_JOBS;
    else if (
      router.pathname === RoutePath.myna_project ||
      router.pathname === RoutePath.createMynaProject ||
      router.pathname === RoutePath.current_project
    )
      view = CurrentDashBoardView.MYNA_PROJECTS;
    setCurrentView(view);
  }, [router.pathname]);

  const renderSideBar = (
    <div className="-z-0 flex h-full w-full flex-col justify-between ">
      {logo}
      <div className="flex w-full flex-col items-start justify-center">
        <PropertyControlledComponent
          controllerProperty={user.UserDetails?.permissions.view_annotations}
        >
          <Button
            variant="secondary"
            className={
              currentView !== CurrentDashBoardView.ANNONATION_PROJECT
                ? styles.navigationButton
                : styles.currentButton
            }
            onClick={() => handleViewChangeClick(CurrentDashBoardView.ANNONATION_PROJECT)}
          >
            Annotation Projects
          </Button>
        </PropertyControlledComponent>
        <PropertyControlledComponent
          controllerProperty={user.UserDetails?.permissions.view_trainings}
        >
          <Button
            variant="secondary"
            className={
              currentView !== CurrentDashBoardView.TRAINING_JOBS
                ? styles.navigationButton
                : styles.currentButton
            }
            onClick={() => handleViewChangeClick(CurrentDashBoardView.TRAINING_JOBS)}
          >
            Training Jobs
          </Button>
        </PropertyControlledComponent>
        <PropertyControlledComponent
          controllerProperty={user.UserDetails?.permissions.view_projects}
        >
          <Button
            variant="secondary"
            className={
              currentView !== CurrentDashBoardView.MYNA_PROJECTS
                ? styles.navigationButton
                : styles.currentButton
            }
            onClick={() => handleViewChangeClick(CurrentDashBoardView.MYNA_PROJECTS)}
          >
            Myna Projects
          </Button>
        </PropertyControlledComponent>
      </div>
      <div className="mb-16">
        {/* <EditUser />
        <ConfirmationDialog
          dialogTitle="Do you want to delete your account ?"
          closeButtonText="No"
          isButtonRequired={true}
          options={[{ optionText: 'Yes', onSelect: handleDeleteUser }]}
          handleButtonText="Delete User"
          handleButtonClassname={styles.Usr}
        /> */}
      </div>
    </div>
  );

  const toasterStatus = useSelector((state: RootState) => {
    return state.status;
  });
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (toasterStatus.status.message !== '') {
      setOpen(true);
    }
  }, [toasterStatus]);

  const handleBack = () => {
    ConfirmationModal.show({
      visible: true,
      title: 'Go back !!',
      bodyElement: (
        <div className="mb-4 flex w-full justify-center text-sm">
          Are you sure you want to go back? All the changes here will be lost
        </div>
      ),
      onSubmitCb: handleBacktoProjects,
      loading: false,
      submitBtnText: 'Yes, go back'
    });
  };
  const handleRedirectProfile = () => {
    router.push(`/User`);
  };
  const profileRedirect = () => {
    ConfirmationModal.show({
      visible: true,
      title: 'User Profile !!',
      bodyElement: (
        <div className="mb-4 flex w-full justify-center text-sm">
          Are you sure you want to go redirect to Profile
        </div>
      ),
      onSubmitCb: handleRedirectProfile,
      loading: false,
      submitBtnText: 'Yes, redirect'
    });
  };

  const snippets = useSelector((state: RootState) => {
    const filteredProjects = _filter(
      state.snippets.present.projectSnippets,
      (Project) => Project.projectId === project?.annotation_project_id
    );
    if (filteredProjects.length) return filteredProjects[0].snippets;
    return null;
  }, _isEqual);

  const target_snippets = useSelector((state: RootState) => {
    const targetSnippets = state.snippets.present.targetSnippets;
    if (targetSnippets && router.query.project_id) {
      const currentTargetSnippet = _find(
        targetSnippets,
        (snippet) => snippet.projectId === router.query.project_id
      );
      if (currentTargetSnippet && currentTargetSnippet.snippets)
        return currentTargetSnippet.snippets;
      return null;
    }
    return null;
  });

  const handleDownloadSRT = async () => {
    if (pathname === RoutePath.editTarget)
      downloadSRT(project?.annotation_project_id, target_snippets);
    else await downloadSRT(project?.annotation_project_id, snippets);
  };

  const handleDownloadVideo = (e) => {
    e.stopPropagation();
    window.open(project?.source_video_file, '_blank');
  };

  const handleBacktoProjects = useCallback(async () => {
    if (pathname === RoutePath.projectEdit) {
      const data = await updateProjectStatus(project.annotation_project_id, PROJECT_STATUS.DRAFT);
      if (data) {
        await refetch().unwrap();
        router.push(RoutePath.dashboard);
      }
    } else if (pathname === '/User') router.push(RoutePath.dashboard);
    else if (pathname === RoutePath.create_job || pathname === RoutePath.current_job) {
      router.push(RoutePath.training_job);
    } else if (pathname === RoutePath.createMynaProject || pathname === RoutePath.current_project) {
      router.push(RoutePath.myna_project);
    } else {
      if (pathname === RoutePath.editSnippets) {
        const data = await updateProjectStatus(
          project.annotation_project_id,
          PROJECT_STATUS.SPLIT_MERGE_PROCESSING
        );
        if (data) {
          await refetch().unwrap();
          router.push(`/projects/${project.annotation_project_id}`);
        }
      } else if (pathname === RoutePath.phonemeEdit) {
        const data = await updateProjectStatus(
          project.annotation_project_id,
          PROJECT_STATUS.EDIT_SNIPPETS_PROCESSING
        );

        if (data) {
          await refetch().unwrap();
          router.push(`/projects/${project.annotation_project_id}/edit-snippets`);
        }
      } else if (pathname === RoutePath.completedAnnotation) {
        const data = await updateProjectStatus(
          _get(project, 'annotation_project_id') || (router.query.project_id as string),
          PROJECT_STATUS.MFA_PROCESSED
        );

        if (data) {
          await refetch().unwrap();
          router.push(
            `/projects/${
              _get(project, 'annotation_project_id') || (router.query.project_id as string)
            }/phoneme-verification`
          );
        }
      } else if (pathname === RoutePath.editTarget) {
        const annotationProjectId = router.query?.project_id;
        router.push({
          pathname: RoutePath.completedAnnotation,
          query: {
            project_id: annotationProjectId
          }
        });
      }
    }
  }, [dispatch, pathname, project, router, updateProjectStatus]);

  const [toggleModal, setModal] = useState<boolean>(false);
  const [filesUploaded, setFilesUploaded] = useState<
    Record<'UPDATE_VIDEO', 'no_file' | 'uploading' | 'uploaded'>
  >({
    UPDATE_VIDEO: 'no_file'
  });

  const handleUploadVideo = () => {
    uploadMediav2(project?.annotation_project_id);
  };
  useEffect(() => {
    if (!isUploading) {
      setModal(false);
    }
  }, [isUploading]);
  const handleVideoModalClose = () => {
    setModal((prev) => !prev);
  };
  return (
    <div className="content">
      <ConfirmationModal />
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={toasterStatus.status.type === 'error' ? 3000 : 1500}
        onClose={() => {
          setOpen(false);
          dispatch(setStatus({ message: '' }));
        }}
      >
        <Alert className={styles.alert} severity={toasterStatus.status.type}>
          {toasterStatus.status.message}
        </Alert>
      </Snackbar>
      <Modal open={toggleModal} className="flex h-full w-full items-center justify-center">
        <Paper className="flex h-fit w-fit flex-col justify-center !bg-[#424242] pb-[60px] pt-[20px]">
          <div className="mb-[40px] flex h-[30px] w-full justify-end">
            <button className="m-3 mr-4 " onClick={handleVideoModalClose}>
              <Close fontSize="medium" className="text-white" />
            </button>
          </div>
          <S3FileUploadComponent
            disabled={false}
            id="upload-video"
            projectId={project?.annotation_project_id}
            accept="video/*"
            text="Upload Video"
            setFilesUploaded={setFilesUploaded}
            fileType="UPDATE_VIDEO"
          />
          <div className="mt-[30px] flex justify-center">
            <Button
              disabled={filesUploaded['UPDATE_VIDEO'] !== 'uploaded'}
              onClick={handleUploadVideo}
            >
              {isUploading ? <CircularProgress sx={{ color: '#ffffff' }} size={20} /> : 'Upload'}
            </Button>
          </div>
        </Paper>
      </Modal>
      <PropertyControlledComponent
        controllerProperty={CustomerAuth.isAuthenticated && pathname !== '/login'}
      >
        <Header logo={logo} sidebar_children={renderSideBar}>
          <>
            <PropertyControlledComponent controllerProperty={pathname === RoutePath.projectEdit}>
              <div className={styles.header}>Split N Merge</div>
            </PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={pathname === RoutePath.editSnippets}>
              <div className={styles.header}>Edit Snippets</div>
            </PropertyControlledComponent>
            <PropertyControlledComponent
              controllerProperty={pathname === RoutePath.dashboard || pathname === '/'}
            >
              <div className={styles.header}>Annotation Projects</div>
            </PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={pathname === RoutePath.training_job}>
              <div className={styles.header}>Training Job</div>
            </PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={pathname === RoutePath.myna_project}>
              <div className={styles.header}>Myna Projects</div>
            </PropertyControlledComponent>
            <PropertyControlledComponent controllerProperty={pathname === RoutePath.phonemeEdit}>
              <div className={styles.header}>Phoneme Verification</div>
            </PropertyControlledComponent>
            <div className="flex w-full flex-col justify-between">
              <div className={`${styles.status} mb-3`}>
                <div className="mx-2 flex flex-row  items-center text-xs">
                  <span className="text-md font-semibold text-gray-400">User Name : </span>
                  <span className="text-blue-300">{user && user?.UserDetails?.name}</span>
                </div>
                <PropertyControlledComponent
                  controllerProperty={
                    pathname != RoutePath.dashboard &&
                    pathname != '/' &&
                    pathname != '/User' &&
                    currentView === CurrentDashBoardView.ANNONATION_PROJECT
                  }
                >
                  <div className="mx-2 flex flex-row  items-center text-xs">
                    <span className="text-md font-semibold text-gray-400">Project Name : </span>
                    <span className="text-blue-300">{project && project?.title}</span>
                  </div>
                </PropertyControlledComponent>
              </div>
              <div className={`${styles.status} `}>
                <PropertyControlledComponent
                  controllerProperty={
                    pathname != RoutePath.dashboard &&
                    pathname != '/' &&
                    pathname != '/User' &&
                    currentView === CurrentDashBoardView.ANNONATION_PROJECT
                  }
                >
                  <div className="mx-2 flex flex-row  items-center text-xs">
                    <span className="text-md font-semibold text-gray-400">Status : </span>
                    <span className="text-blue-300">
                      {project && project.status.toLocaleUpperCase()}
                    </span>
                  </div>
                  <div className="mx-2 flex flex-row  items-center text-xs">
                    <span className="text-md font-semibold text-gray-400">Sampling Rate : </span>
                    <span className="text-blue-300">{project && project?.audio_sample_rate}</span>
                  </div>
                </PropertyControlledComponent>
                <div className="lg+:mt-0 mt-4 flex flex-row">
                  <PropertyControlledComponent
                    controllerProperty={
                      pathname != RoutePath.training_job &&
                      currentView === CurrentDashBoardView.TRAINING_JOBS
                    }
                  >
                    <div className="mx-2 flex flex-row  items-center text-xs">
                      <span className="text-md font-semibold text-gray-400">Status : </span>
                      <span className="text-blue-300">
                        {trainingDetails && trainingDetails.training_status.toLocaleUpperCase()}
                      </span>
                    </div>
                  </PropertyControlledComponent>
                  <PropertyControlledComponent
                    controllerProperty={pathname === RoutePath.completedAnnotation}
                  >
                    <Button
                      variant="secondary"
                      className={`mr-4 ${styles.logoutBtn} !text-sm`}
                      onClick={handleVideoModalClose}
                    >
                      Upload Replacement Video
                    </Button>
                  </PropertyControlledComponent>

                  <PropertyControlledComponent
                    controllerProperty={
                      pathname != RoutePath.dashboard &&
                      pathname != RoutePath.completedAnnotation &&
                      pathname != '/' &&
                      pathname != RoutePath.training_job &&
                      pathname != RoutePath.myna_project
                    }
                  >
                    <Button
                      variant="tertiary"
                      className={`mr-6 lowercase ${styles.newProject}`}
                      onClick={handleBack}
                    >
                      <span className="lg+:flex  hidden text-sm">Go Back</span>
                      <Tooltip title="Go Back">
                        <span className="lg+:hidden flex">
                          <ArrowBack />
                        </span>
                      </Tooltip>
                    </Button>
                  </PropertyControlledComponent>
                  <PropertyControlledComponent
                    controllerProperty={pathname === RoutePath.dashboard || pathname === '/'}
                  >
                    <Button
                      variant="secondary"
                      className={`mr-4 ${styles.newProject}`}
                      onClick={newProjectHandler}
                    >
                      New Project
                    </Button>
                  </PropertyControlledComponent>
                  <PropertyControlledComponent
                    controllerProperty={pathname === RoutePath.training_job}
                  >
                    <Button
                      variant="secondary"
                      className={`mr-4 ${styles.newProject}`}
                      onClick={handleNewTrainingJob}
                    >
                      New Training Job
                    </Button>
                  </PropertyControlledComponent>
                  <PropertyControlledComponent
                    controllerProperty={pathname === RoutePath.myna_project}
                  >
                    <Button
                      variant="secondary"
                      className={`mr-4 ${styles.newProject}`}
                      onClick={handleNewMynaProject}
                    >
                      New Myna Project
                    </Button>
                  </PropertyControlledComponent>
                  <PropertyControlledComponent
                    controllerProperty={
                      pathname === RoutePath.editSnippets ||
                      pathname === RoutePath.phonemeEdit ||
                      pathname === RoutePath.projectEdit ||
                      pathname === RoutePath.completedAnnotation ||
                      pathname === RoutePath.editTarget
                    }
                  >
                    <Button
                      variant="secondary"
                      className={styles.logoutBtn}
                      onClick={handleDownloadVideo}
                    >
                      <span className="lg+:flex  hidden text-sm">Download Source Video</span>
                      <Tooltip title="Download Source Video">
                        <span className="lg+:hidden flex">
                          <Download />
                        </span>
                      </Tooltip>
                    </Button>
                  </PropertyControlledComponent>
                  <PropertyControlledComponent
                    controllerProperty={
                      pathname === RoutePath.editSnippets ||
                      pathname === RoutePath.phonemeEdit ||
                      pathname === RoutePath.projectEdit ||
                      pathname === RoutePath.completedAnnotation ||
                      pathname === RoutePath.editTarget
                    }
                  >
                    <Button
                      variant="secondary"
                      className={styles.logoutBtn}
                      onClick={handleDownloadSRT}
                    >
                      <span className="lg+:flex  hidden text-sm">Download SRT</span>
                      <Tooltip title="Download SRT">
                        <span className="lg+:hidden flex">
                          <Download />
                        </span>
                      </Tooltip>
                    </Button>
                  </PropertyControlledComponent>
                  <Button variant="secondary" className={styles.logoutBtn} onClick={logOuthandler}>
                    <span className="lg+:flex  hidden text-sm">Logout</span>
                    <Tooltip title="Logout">
                      <span className="lg+:hidden flex">
                        <Logout />
                      </span>
                    </Tooltip>
                  </Button>
                  <Button
                    variant="secondary"
                    className={styles.logoutBtn}
                    onClick={profileRedirect}
                  >
                    <Tooltip title="Profile">
                      <AccountCircleOutlined />
                    </Tooltip>
                  </Button>
                </div>
              </div>
            </div>
          </>
        </Header>
      </PropertyControlledComponent>
      {pathname === '/login' || pathname === '/reset_password' || pathname === '/verify_email' ? (
        <div className="absolute h-full w-full overflow-scroll  pl-[60px] ">{children}</div>
      ) : (
        <div className={styles.layoutChildren}>{children}</div>
      )}

      <ProjectModal />
    </div>
  );
}
