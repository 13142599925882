import { AxiosError } from 'axios';

import { QueryMeta } from '../types/baseQueryTypes';
import { setStatus } from 'slices/StatusSlice';
import { ErrorResponse } from 'types/Error';

const formatErrorDetails = (error: AxiosError<any, any>): ErrorResponse => ({
  error: error.response?.data.error || 'Exception',
  message: error.response?.data.message || 'Something went wrong. Please try again!',
  statusCode: error.response?.data.statusCode || 500
});

export const errorResponseHandler = <TError = unknown>(
  errResponse: AxiosError<TError>,
  meta: QueryMeta,
  toastMessage: string
) => {
  const { message, statusCode } = formatErrorDetails(errResponse);
  meta.api.dispatch(
    setStatus({
      type: 'error',
      message: `${toastMessage}${`, ${message}`}. STATUS_CODE: ${statusCode}`
    })
  );
  return message;
};
