import _isEqual from 'lodash/isEqual';
export function undoable(reducer) {
  const initialState = {
    past: [],
    present: reducer(undefined, {}),
    future: []
  };

  return function (state = initialState, action) {
    const { past, present, future } = state;
    switch (action.type) {
      case 'UNDO': {
        if (past.length - 1 >= 0) {
          const previous = past[past.length - 1];
          const newPast = past.slice(0, past.length - 1);
          return {
            past: newPast,
            present: previous,
            future: [present, ...future]
          };
        }
        return state;
      }
      case 'REDO': {
        if (future.length !== 0) {
          const next = future[0];
          const newFuture = future.slice(1);
          return {
            past: [...past, present],
            present: next,
            future: newFuture
          };
        }
        return state;
      }
      default: {
        const newPresent = reducer(present, action);
        if (_isEqual(newPresent, present)) {
          return state;
        } else if (present.projectSnippets.length === 0 || !present.projectSnippets[0].snippets) {
          return {
            past: [],
            present: newPresent,
            future: []
          };
        }
        return {
          past: [...past, present],
          present: newPresent,
          future: []
        };
      }
    }
  };
}
