import PropertyControlledComponent from '@libs/atoms/PropertyControlledComponent';
import { Close } from '@mui/icons-material';
import { Modal } from '@mui/material';
import ProjectCreation from 'components/Newproject/ProjectCreation/ProjectCreation';
import ProjectUpload from 'components/Newproject/ProjectUpload/ProjectUpload';
import { motion } from 'framer-motion';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectModal } from 'slices/ProjectSlice';
import { RootState } from 'store/AppStore';

import { ScreenFlowTypes } from './newproject.types';
import styles from './ProjectModal.module.scss';
export default function ProjectModal() {
  const dispatch = useDispatch();
  const projectModal = useSelector((state: RootState) => {
    return state.project.projectModal.showProjectModal;
  });
  const currentScreen = useSelector((state: RootState) => {
    return state.project.projectModal.currentScreen;
  });
  const getScreen = () => {
    switch (currentScreen) {
      case 0: {
        return <ProjectCreation />;
      }
      case 1: {
        return (
          <>
            <ProjectUpload />
          </>
        );
      }
      default: {
        break;
      }
    }
    return <></>;
  };

  const handleClose = () => {
    dispatch(setProjectModal({ showProjectModal: false, currentScreen: ScreenFlowTypes.DETAILS }));
  };

  return (
    <div className={`${styles.projectModalContainer}`}>
      <PropertyControlledComponent controllerProperty={projectModal}>
        <Modal open={projectModal}>
          <motion.div
            initial={{ x: -300 }}
            animate={{ x: 0 }}
            exit={{ x: -300, transition: { delay: 1, duration: 0.6 } }}
            className="absolute left-[20%] top-[15%] flex h-[80%] w-[60%] flex-col overflow-scroll rounded-xl bg-[#242424]"
          >
            <div className="flex h-[30px] w-full justify-end">
              <motion.button whileHover={{ scale: 1.2 }} className="m-3 mr-4" onClick={handleClose}>
                <Close fontSize="medium" className="text-white" />
              </motion.button>
            </div>
            {getScreen()}
          </motion.div>
        </Modal>
      </PropertyControlledComponent>
    </div>
  );
}
