import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import _noop from 'lodash/noop';
import React, { PureComponent } from 'react';

import { Button } from '../Button';

import styles from './ConfirmationModal.module.scss';

const DEFAULT_STATE = {
  visible: false,
  title: '',
  bodyElement: <></>,
  onSubmitCb: _noop,
  loading: false,
  submitBtnText: 'Save',
  width: '',
  dialogClassName: '',
  onSecondSubmitCb: _noop,
  secondSubmitTxt: '',
  onCancelCb: _noop
};

interface TRIGGER_PROPS {
  visible: boolean;
  title: string;
  bodyElement: JSX.Element;
  submitBtnText: string;
  onSubmitCb: () => void;
  onCancelCb?: () => void;
  secondSubmitTxt?: string;
  onSecondSubmitCb?: () => void;
  dialogClassName?: string;
  width?: string;
  loading: boolean;
}
// interface Props {}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default class ConfirmationModal extends PureComponent<any, any> {
  static confirmationModalRef = new ConfirmationModal({});

  static show(props: TRIGGER_PROPS) {
    if (ConfirmationModal.confirmationModalRef) {
      ConfirmationModal.confirmationModalRef.show(props);
    }
  }

  static close() {
    if (ConfirmationModal.confirmationModalRef) {
      ConfirmationModal.confirmationModalRef.close();
    }
  }

  constructor(props: unknown) {
    super(props);
    this.state = { ...DEFAULT_STATE };
    ConfirmationModal.confirmationModalRef = this;
  }

  static getConfirmation(props: TRIGGER_PROPS) {
    return new Promise((resolve) => {
      if (ConfirmationModal.confirmationModalRef) {
        ConfirmationModal.confirmationModalRef.show({
          ...props,
          onSubmitCb: () => {
            resolve(true);
            ConfirmationModal.confirmationModalRef.close();
          },
          onSecondSubmitCb: () => {
            resolve(true);
            ConfirmationModal.confirmationModalRef.close();
          },
          onCancelCb: () => {
            resolve(false);
            ConfirmationModal.confirmationModalRef.close();
          }
        });
      }
    });
  }

  close = () => {
    this.setState({ visible: false });
  };

  onSubmit = () => {
    const { onSubmitCb } = this.state;
    ConfirmationModal.close();
    onSubmitCb();
  };
  onSecondarySubmit = () => {
    const { onSecondSubmitCb } = this.state;
    ConfirmationModal.close();
    if (onSecondSubmitCb) onSecondSubmitCb();
  };

  onCancel = () => {
    const { onCancelCb } = this.state;
    if (onCancelCb) {
      onCancelCb();
    }
    this.close();
  };

  show = ({
    title,
    bodyElement,
    submitBtnText,
    onSubmitCb = _noop,
    onCancelCb,
    secondSubmitTxt,
    onSecondSubmitCb = _noop,
    dialogClassName,
    width
  }: TRIGGER_PROPS) => {
    this.setState({
      visible: true,
      title,
      bodyElement,
      onSubmitCb,
      onCancelCb,
      submitBtnText,
      secondSubmitTxt,
      onSecondSubmitCb,
      dialogClassName,
      width
    });
  };

  render() {
    const { title, bodyElement, visible, submitBtnText, dialogClassName, secondSubmitTxt, width } =
      this.state;

    return (
      <Dialog
        open={visible}
        keepMounted
        onClose={this.close}
        aria-describedby="alert-dialog-slide-description"
        className={`${styles.dialogContainer} ${dialogClassName}`}
        PaperProps={{
          style: {
            width: width || '600px',
            padding: '10px 20px',
            borderRadius: '20px'
          }
        }}
      >
        <DialogTitle className=" bg-gan-white flex w-full justify-center font-bold text-black">
          {title}
        </DialogTitle>

        {bodyElement}

        <DialogActions className="bg-gan-white flex w-full justify-end">
          <Button variant="primary" className={styles.dialogBtn} onClick={this.onSubmit}>
            {submitBtnText}
          </Button>
          {secondSubmitTxt && (
            <Button variant="primary" className={styles.dialogBtn} onClick={this.onSecondarySubmit}>
              {secondSubmitTxt}
            </Button>
          )}
          <Button variant="generic" onClick={this.onCancel} className="text-white">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
