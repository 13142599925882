import PropertyControlledComponent from '@libs/atoms/PropertyControlledComponent';
import MenuIcon from '@mui/icons-material/Menu';
import { Button as MuiButton } from '@mui/material';
import React, { ReactElement, useCallback, useState } from 'react';

import styles from './Header.module.scss';
import Sidebar from './Sidebar';

interface Props {
  children: ReactElement;
  sidebar_children: ReactElement;
  logo: ReactElement;
}
export default function Header(props: Props) {
  const { children, sidebar_children, logo } = props;
  const [sideBar, setSideBar] = useState(false);
  const handleSideBar = useCallback(() => {
    setSideBar((prev) => !prev);
  }, []);
  return (
    <div className="w-100 flex h-[100px] items-center bg-[#121212] text-white">
      <PropertyControlledComponent controllerProperty={!sideBar}>
        <MuiButton onClick={handleSideBar} className={`${styles.sideBarToggler} `}>
          <MenuIcon color="inherit" className="text-white" fontSize="large" />
        </MuiButton>
        {logo}
      </PropertyControlledComponent>
      {children}

      <PropertyControlledComponent controllerProperty={sideBar}>
        <div className="absolute top-0 z-10">
          <Sidebar handleSideBar={handleSideBar}>{sidebar_children}</Sidebar>
        </div>
      </PropertyControlledComponent>
    </div>
  );
}
