import { Button } from '@libs/atoms/Button';
import PropertyControlledComponent from '@libs/atoms/PropertyControlledComponent';
import { InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { useProject } from 'hooks/useProject';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectModal } from 'slices/ProjectSlice';
import { setLoading, setStatus } from 'slices/StatusSlice';
import { useGetAnnotationProjectDetailsQuery } from 'store/api/slices/getApis/getApisSlice';
import { RootState } from 'store/AppStore';
import {
  ASR_TYPE,
  LANGUAGE_OPTIONS,
  LANGUAGE_TYPE,
  MODAL_LANG_TYPE,
  SAMPLING_RATES,
  SAMPLING_TYPE
} from 'types/Project';

import { ScreenFlowTypes } from '../newproject.types';

import styles from './ProjectUpload.module.scss';
import S3FileUploadComponent from './S3FileUploadComponent';

export default function ProjectUpload() {
  const [srcLang, setSrcLang] = useState<LANGUAGE_TYPE>();
  const [modalLang, setModalLang] = useState<MODAL_LANG_TYPE>();
  const [asrService, setAsrService] = useState<ASR_TYPE>();
  const [samplingRate, setSamplingRate] = useState<SAMPLING_TYPE>('FORTY_FOUR');
  const { updateProjectDetails } = useProject();
  const router = useRouter();
  const [filesUploaded, setFilesUploaded] = useState<
    Record<'SRT' | 'MEDIA' | 'AUDIO', 'no_file' | 'uploading' | 'uploaded'>
  >({
    SRT: 'no_file',
    MEDIA: 'no_file',
    AUDIO: 'no_file'
  });
  const dispatch = useDispatch();
  const checkFilesUploaded = (
    key: 'SRT' | 'MEDIA' | 'AUDIO',
    value: 'no_file' | 'uploading' | 'uploaded'
  ) => filesUploaded[key] === value;

  const currentProjectId = useSelector((state: RootState) => {
    return state.project.projectModal.currentProject;
  });

  const { data: currentProject } = useGetAnnotationProjectDetailsQuery(currentProjectId, {
    skip: !currentProjectId
  });

  //only allow user to save when language and video is set, srt is not mandatory
  // also disable saving when files are not uploaded yet !!
  const handleSave = async () => {
    if (
      (checkFilesUploaded('MEDIA', 'uploaded') ||
        !_isEmpty(currentProject.downsampled_source_video_file)) &&
      (srcLang || !_isEmpty(currentProject.source_language_code)) &&
      (modalLang || !_isEmpty(currentProject.model_language_code)) &&
      (asrService || !_isEmpty(currentProject.asr_service) || checkFilesUploaded('SRT', 'uploaded'))
    ) {
      if (
        modalLang &&
        srcLang &&
        checkFilesUploaded('MEDIA', 'uploaded') &&
        (asrService || checkFilesUploaded('SRT', 'uploaded'))
      ) {
        try {
          dispatch(setLoading(true));
          await updateProjectDetails(
            modalLang,
            srcLang,
            currentProjectId,
            samplingRate,
            [
              checkFilesUploaded('MEDIA', 'uploaded'),
              checkFilesUploaded('AUDIO', 'uploaded'),
              checkFilesUploaded('SRT', 'uploaded')
            ],
            asrService
          );
          dispatch(
            setProjectModal({ showProjectModal: false, currentScreen: ScreenFlowTypes.DETAILS })
          );
          dispatch(setLoading(false));
          router.push('/dashboard');
        } catch (error) {
          console.log({ error });
          dispatch(setStatus({ type: 'error', message: 'Upload failed' }));
        }
      }
    }
  };

  const handleLangChange = (event: SelectChangeEvent) => {
    setSrcLang(event.target.value as LANGUAGE_TYPE);
  };
  const handleModalLangChange = (event: SelectChangeEvent) => {
    setModalLang(event.target.value as MODAL_LANG_TYPE);
  };
  const handleASRService = (event: SelectChangeEvent) => {
    setAsrService(event.target.value as ASR_TYPE);
  };

  return (
    <div className="flex h-full w-full flex-col items-center justify-between p-2">
      <div className="my-4 flex w-full flex-row justify-center text-2xl text-white ">
        Upload Project Data
      </div>
      <div className="my-2 grid grid-cols-2 gap-24 gap-y-12 ">
        <PropertyControlledComponent
          controllerProperty={
            currentProject && _isEmpty(currentProject.downsampled_source_video_file)
          }
        >
          <S3FileUploadComponent
            accept="video/*"
            disabled={false}
            id="upload-photo"
            projectId={currentProjectId}
            text="Upload Video"
            setFilesUploaded={setFilesUploaded}
            fileType="MEDIA"
          />
        </PropertyControlledComponent>
        <PropertyControlledComponent
          controllerProperty={currentProject && _isEmpty(currentProject.source_subtitle_file)}
        >
          <S3FileUploadComponent
            accept=".srt"
            disabled={asrService ? true : false}
            id="srt-photo"
            projectId={currentProjectId}
            text="Upload Srt"
            setFilesUploaded={setFilesUploaded}
            fileType="SRT"
          />
        </PropertyControlledComponent>
        <PropertyControlledComponent
          controllerProperty={currentProject && _isEmpty(currentProject.source_audio_file)}
        >
          <S3FileUploadComponent
            accept=".mp3,.wav"
            disabled={asrService ? true : false}
            id="audio"
            projectId={currentProjectId}
            text="Upload Audio"
            setFilesUploaded={setFilesUploaded}
            fileType="AUDIO"
          />
        </PropertyControlledComponent>
        <PropertyControlledComponent
          controllerProperty={currentProject && _isEmpty(currentProject.sampling_rate)}
        >
          <div className="mb-4">
            <InputLabel id="simple-select-label" className={styles.inputSrc}>
              Sampling Rate
            </InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={samplingRate}
              sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              label=" Sample rate*"
              className={styles.selectLang}
              onChange={(e) => setSamplingRate(e.target.value as SAMPLING_TYPE)}
              inputProps={{ 'aria-label': 'Without label' }}
              displayEmpty
            >
              {_map(Object.keys(SAMPLING_RATES), (lang) => {
                return <MenuItem value={lang}>{SAMPLING_RATES[lang]}</MenuItem>;
              })}
            </Select>
          </div>
        </PropertyControlledComponent>
        <PropertyControlledComponent
          controllerProperty={currentProject && _isEmpty(currentProject.source_language_code)}
        >
          <div className="mb-4">
            <InputLabel id="simple-select-label" className={styles.inputSrc}>
              Source Language
            </InputLabel>
            <Select
              labelId="simple-select-label"
              id="simple-select"
              value={srcLang}
              // sx={{}
              sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              label=" Src Lang*"
              className={styles.selectLang}
              onChange={handleLangChange}
              inputProps={{ 'aria-label': 'Without label' }}
              displayEmpty
            >
              {_map(LANGUAGE_OPTIONS, (lang) => {
                return <MenuItem value={lang}>{lang}</MenuItem>;
              })}
            </Select>
          </div>
        </PropertyControlledComponent>
        <PropertyControlledComponent
          controllerProperty={currentProject && _isEmpty(currentProject.model_language_code)}
        >
          <div className="mb-4">
            <InputLabel id="simple-select-label2" className={styles.inputSrc}>
              Model Language
            </InputLabel>
            <Select
              labelId="simple-select-label2"
              id="simple-select2"
              value={modalLang}
              sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              label=" Model Lang*"
              className={styles.selectLang}
              onChange={handleModalLangChange}
            >
              <MenuItem value="en-us">en-US</MenuItem>
              <MenuItem value="hi">hi</MenuItem>
            </Select>
          </div>
        </PropertyControlledComponent>
        <PropertyControlledComponent
          controllerProperty={currentProject && _isEmpty(currentProject.asr_service)}
        >
          <div className="mb-4">
            <InputLabel id="simple-select-label2" className={styles.inputSrc}>
              ASR Service
            </InputLabel>
            <Select
              labelId="simple-select-label2"
              id="simple-select2"
              disabled={checkFilesUploaded('SRT', 'uploaded') ? true : false}
              value={asrService}
              sx={{ boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 } }}
              label=" Model Lang*"
              className={styles.selectLang}
              onChange={handleASRService}
            >
              <MenuItem value="google_asr">Google</MenuItem>
              <MenuItem value="aws_asr">AWS</MenuItem>
              <MenuItem value="whisper_asr">Whisper</MenuItem>
              <MenuItem value="deepgram_asr">Deepgram</MenuItem>
            </Select>
          </div>
        </PropertyControlledComponent>
      </div>
      <Button
        variant="secondary"
        disabled={
          checkFilesUploaded('MEDIA', 'uploading') ||
          checkFilesUploaded('AUDIO', 'uploading') ||
          checkFilesUploaded('SRT', 'uploading') ||
          checkFilesUploaded('MEDIA', 'no_file')
        }
        className={styles.nxtBtn}
        onClick={handleSave}
      >
        Save
      </Button>
    </div>
  );
}
