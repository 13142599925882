export enum ColorVariant {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  TERTIARY = 'tertiary',
  GENERIC = 'generic'
}
export enum SizeVariant {
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm'
}
export type VariantType = 'primary' | 'secondary' | 'tertiary' | 'generic';

export type SizeType = 'sm' | 'lg' | 'md';
