import { CircularProgress } from '@mui/material';
import React from 'react';

import styles from './Loader.module.scss';
interface LoaderProps {
  loaderText: string;
  className?: string;
}

const Loader: React.FC<LoaderProps> = ({ loaderText, className }) => (
  <div className={styles.container}>
    <CircularProgress className="mr-2" />
    <span className={`${styles.text} text-black ${className}`}>{loaderText}</span>
  </div>
);

export default Loader;
