import { Button } from '@libs/atoms/Button';
import { NextRouter, withRouter } from 'next/router';
import { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children?: ReactNode;
  router: NextRouter;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // console.error('Uncaught error:', error, errorInfo);
  }

  handleClick = () => {
    const { router } = this.props;
    router.back();
  };

  public render() {
    if (this.state.hasError) {
      return (
        <div className="error-bg flex min-h-screen items-center justify-center  bg-[#191919] bg-cover bg-fixed bg-bottom">
          <div className="flex items-center justify-center  bg-[#121212] text-center text-white">
            <div className="flex flex-col items-center p-10">
              <img
                className="rounded-lg"
                // eslint-disable-next-line react/jsx-curly-brace-presence -- this is a valid use case for curly braces
                src={
                  'https://images.unsplash.com/photo-1453227588063-bb302b62f50b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=400&q=80'
                }
                alt="dog"
              />
              <h1 className="text-gan-gray app-font my-2 text-2xl font-bold">
                Woops! <br /> Something went wrong :(
              </h1>
              <Button onClick={this.handleClick}>Go back</Button>
            </div>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default withRouter(ErrorBoundary);
