//User Login
export const LOGIN_PATH = '/users/login';
export const REFRESH_TOKEN_PATH = 'users/refresh_token';
export const DELELTE_USER_PATH = 'users/delete';
export const SIGNUP_PATH = '/users/signup';
export const FORGOT_PASSWORD = '/users/forgot_password';
export const CHANGE_PASSWORD = '/users/reset_password';
export const SEND_VERIFY_EMAIL = '/users/send_email_verification';
export const VERIFY_EMAIL = '/users/verify';

//User details
export const GET_USER_DETAILS = 'users/detail';
export const UPATE_USER_DETAILS = 'users/update';

export const PROJECTS_PATH = '/projects';
export const MYNA_PATH = '/project_ops';
export const TRAININGJOB_PATH = '/training_jobs';
export const AUDIO_SYNC = '/audio_sync';
export const COMPLETED_TRAINING_JOBS = 'completed_training_jobs';
export const COMPLETED_PROJECTS = 'completed_projects';
export const MULTIMODEL_PROJECT = 'multimodel_project';
export const MULTIMODEL_PROJECTS = 'multimodel_projects';
export const LANGUAGE_UPDATE = 'language_update';
export const ASR_UPDATE = 'asr_service_update';
export const SOURCE_SNIPPETS = 'source_snippets';
export const SPLIT_MERGE_SNIPPETS = 'split_merge_snippets';
export const VERIFY_SNIPPETS = 'verify_snippets';
export const VERIFY_CTC = 'ctc_timestamps_snippets';
export const EDIT_TEXT_SNIPPETS = 'edit_text_snippets';
export const MFA_SNIPPETS = 'mfa_snippets';
export const STATUS = 'status';
export const UPDATE = 'update';
export const EDIT_TITLE = 'edit_title';

// downsampling, upsampling
export const GET_DOWNSAMPLING_MODES = '/common_apis/video_downsampling_modes';
export const GET_UPSAMPLING_MODES = '/common_apis/video_upsampling_modes';
