import { AuthTokens } from 'types/AuthTokens';
import { User, Token as UserToken } from 'types/User';

import LocalStorage from './storage';

class CustomerAuth {
  static clearTokens(): void {
    LocalStorage.remove(AuthTokens.ACCESS_TOKEN);
    LocalStorage.remove(AuthTokens.REFRESH_TOKEN);
    LocalStorage.remove(AuthTokens.USER_INFO);
  }

  static setTokens(token: UserToken): void {
    LocalStorage.setItem(AuthTokens.ACCESS_TOKEN, token.accessToken);
    LocalStorage.setItem(AuthTokens.REFRESH_TOKEN, token.refreshToken);
  }

  static setUserInfo(user: User): void {
    LocalStorage.setItem(AuthTokens.USER_INFO, JSON.stringify(user));
  }

  static get refreshToken(): string | null {
    return LocalStorage.getItem(AuthTokens.REFRESH_TOKEN);
  }

  static get accessToken(): string | null {
    return LocalStorage.getItem(AuthTokens.ACCESS_TOKEN);
  }

  static get userInfo(): User | null {
    if (LocalStorage.getItem(AuthTokens.USER_INFO) !== null) {
      return JSON.parse(LocalStorage.getItem(AuthTokens.USER_INFO) as string) as User;
    }
    return null;
  }

  static get isAuthenticated(): boolean {
    return Boolean(CustomerAuth.accessToken);
  }
}

export default CustomerAuth;
