import React from 'react';

import Loader from '../loader';

import styles from './Fullscreenloader.module.scss';

const FullscreenLoader = () => (
  <div className={styles.loader__wrapper}>
    <Loader loaderText="Loading" />
  </div>
);

export default FullscreenLoader;
