import { Button } from '@libs/atoms/Button';
import { TextField } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { useProject } from 'hooks/useProject';
import _isEqual from 'lodash/isEqual';
import React, { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { setProjectModal } from 'slices/ProjectSlice';
import { RootState } from 'store/AppStore';

import { ScreenFlowTypes } from '../newproject.types';

import styles from './ProjectCreation.module.scss';

interface IFormInput {
  projectTitle: string;
  description: string;
}
const animationProps = {
  initial: { y: -50, opacity: 0.2 },
  animate: { y: 0, opacity: 1 },
  exit: { y: -10, opacity: 0 }
};

export default function ProjectCreation() {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<IFormInput>();
  const { createProject } = useProject();
  const userId = useSelector((state: RootState) => {
    return state.user.UserDetails.user_id;
  }, _isEqual);

  const onSubmit: SubmitHandler<IFormInput> = useCallback(
    async (data) => {
      const { description, projectTitle } = data;

      const response = await createProject(projectTitle, description, userId);
      if (response !== null) {
        dispatch(
          setProjectModal({ currentScreen: ScreenFlowTypes.UPLOAD, currentProject: response })
        );
      }
    },
    [createProject, dispatch, userId]
  );

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        const submitHoc = handleSubmit(onSubmit);
        submitHoc();
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleSubmit, onSubmit]);

  return (
    <div className="flex h-full w-full items-center justify-center ">
      <div className="flex flex-col items-center justify-center">
        <form onSubmit={handleSubmit(onSubmit)} id="projectForm">
          <div className="mt-2 flex h-[120px] flex-col">
            <label htmlFor="projectname" className="text-md text-white after:content-['*']">
              Project Title
            </label>
            <TextField
              {...register('projectTitle', { required: true })}
              autoComplete="off"
              id="projectname"
              margin="normal"
              type="text"
              className={`w-[400px] bg-[#b2b1b1] text-white  ${styles.inputField}`}
            />
            <AnimatePresence>
              {errors.projectTitle && (
                <motion.span {...animationProps} className="  py-1  text-sm text-red-600">
                  Please Enter Project title
                </motion.span>
              )}
            </AnimatePresence>
          </div>
          <div className="mt-2 flex h-[120px] flex-col">
            <label htmlFor="purpose" className="text-md text-white after:content-['*']">
              Description
            </label>
            <TextField
              autoComplete="off"
              type="text"
              {...register('description', { required: true })}
              id="purpose"
              className={`w-[400px] bg-[#b2b1b1] text-white  ${styles.inputField}`}
            />
            <AnimatePresence>
              {errors.description && (
                <motion.span {...animationProps} className="  py-1  text-sm text-red-600">
                  Please Enter Description
                </motion.span>
              )}
            </AnimatePresence>
          </div>

          <Button onClick={handleSubmit(onSubmit)} variant="secondary" className={styles.nxtBtn}>
            Next
          </Button>
        </form>
      </div>
    </div>
  );
}
