const loginString = {
  passwordCondition: {
    primary_condition: 'Primary conditions for password validation :',
    min_char: 'Minimum 8 characters.',
    alphabet_condition: 'The alphabets must be between [a-z].',
    alphabet_requirement: ' At least one alphabet should be of Upper Case [A-Z].',
    digit_requirement: 'At least 1 number or digit between [0-9].',
    specialchar_requirement: 'At least 1 special character.'
  },
  password: {
    forgotPassword: 'Forgot Password',
    password: 'Password',
    enterPassword: 'Please enter the password',
    enterYourPassword: 'Please enter your password',
    enterValidPassword:
      'Please enter a valid password, should contain 1 caps, one number and one special character',
    confirmPassword: 'Confirm Password',
    matchPassword: 'Passwords should match',
    changePassword: 'Change password'
  },
  login: {
    loginNow: 'Login Now',
    resendVerification: 'Resend verification mail',
    createAccount: 'Create Account',
    loginAccount: 'Login to your account'
  },
  signUp: {
    forgotPasswordQ: 'Forgot Password?',
    thanks: 'Thanks for signing up. Please Verify your email to continue.',
    enterEmail: 'Please enter the email',
    validEmail: 'Please enter a valid email',
    sendMail: 'Send Email',
    goBack: 'Go Back',
    enterPassword: 'Please enter a password',
    name: 'Please enter your name',
    phonenumber: 'Please enter your phone number',
    accessCode: 'Please enter the access code',
    signUp: 'Sign Up',
    successMessage:
      'Your account has been successfully registered. To complete the process please check your email for validation request.'
  },
  mail: {
    resendMail: 'Resend Mail',
    confirmEmail: 'Confirm your Email!',
    email: 'Email',
    enterEmail: 'Please enter your email'
  },
  verify: 'Verify',
  logout: {
    logout: 'LogOut'
  },
  trainingApp: 'Training App',
  backToProjects: 'back to projects'
};

export default loginString;
