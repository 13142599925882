import { AxiosError } from 'axios';

import { ErrorResponse } from '../types/Error';

export const HttpResponse = {
  Conflict: 409,
  Unauthorised: 401,
  InvalidData: 400,
  NotFound: 404
};

export enum Errors {
  BAD_REQUEST = 'BAD_REQUEST'
}
interface errorData {
  error: string;
  message: string;
  statusCode: number;
}
export const formatErrorDetails = (error: AxiosError): ErrorResponse => ({
  error: (error.response.data as errorData).error || 'Exception',
  message: (error.response.data as errorData).message || 'Something went wrong.Please try again!',
  statusCode: (error.response.data as errorData).statusCode || 500
});
