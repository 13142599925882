const routes = {
  home: '/',
  editTarget: '/target-srt/[project_id]',
  user: '/User',
  login: '/login',
  verify: '/verify',
  passwordChange: '/change-password',
  dashboard: '/dashboard',
  training_job: '/training_jobs',
  create_job: '/training_jobs/create-job',
  current_job: '/training_jobs/[training_id]',
  myna_project: '/myna_projects',
  phonemeEdit: '/projects/[project_id]/phoneme-verification',
  projectEdit: '/projects/[project_id]',
  createMynaProject: '/myna_projects/createMynaProject',
  current_project: '/myna_projects/[unique_id]',
  editSnippets: '/projects/[project_id]/edit-snippets',
  completedAnnotation: '/projects/[project_id]/annotation-complete'
};
export default routes;
