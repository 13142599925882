const largeSizeStyles = {
  padding: ' 1.5rem 2rem',
  width: '12.125rem',
  height: '5.25rem',
  borderRadius: '2.5rem',
  fontSize: '1.5rem',
  lineHeight: '2.25rem'
};
const mediumSizeStyles = {
  padding: ' 1.25rem 1.5rem',
  width: '9.8125rem',
  height: '4.375rem',
  borderRadius: '2rem',
  fontSize: '1.25rem',
  lineHeight: '1.875rem'
};
const smallSizeStyles = {
  padding: ' 0.75rem 1.25rem',
  width: '7.9375rem',
  height: '3rem',
  borderRadius: '2.5rem',
  fontSize: '1rem',
  lineHeight: '1.5rem'
};

export type SizeStylesType = typeof smallSizeStyles;
export { largeSizeStyles, mediumSizeStyles, smallSizeStyles };
