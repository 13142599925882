export enum AuthTokens {
  ACCESS_TOKEN = 'access_token',
  REFRESH_TOKEN = 'refresh_token',
  USER_INFO = 'user_info'
}

export interface SignupPayload {
  password: string;
  password_confirmation?: string;
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email_verified?: boolean;
  access_code: string;
}
