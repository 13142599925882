import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { undoable } from 'slices/undoable';

import MynaProjectReducer from '../slices/MynaProjectSlice';
import ProjectReducer from '../slices/ProjectSlice';
import SnippetsReducer from '../slices/ProjectSnippetsSlice';
import StatusReducer from '../slices/StatusSlice';
import UserReducer from '../slices/UserSlice';
import { apiSlice } from './api/apiSlice';

const reducer = combineReducers({
  project: ProjectReducer,
  snippets: undoable(SnippetsReducer),
  mynaproject: MynaProjectReducer,
  user: UserReducer,
  status: StatusReducer,
  [apiSlice.reducerPath]: apiSlice.reducer
});

export const AppStore = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware)
});
export type RootState = ReturnType<typeof reducer>;
