import { Close } from '@mui/icons-material';
import { Button as MuiButton } from '@mui/material';
import { motion } from 'framer-motion';
import React, { ReactElement } from 'react';

import styles from './Header.module.scss';

interface Props {
  handleSideBar: () => void;
  children: ReactElement;
}
const SideBar = (props: Props) => {
  const { children, handleSideBar } = props;
  return (
    <motion.div
      className={styles.closeContainer}
      initial={{ width: 150 }}
      animate={{
        width: 300
      }}
      exit={{
        width: 150,
        transition: { delay: 1, duration: 0.6 }
      }}
    >
      <MuiButton onClick={handleSideBar} className={`${styles.sideBarToggler} relative top-[5px]`}>
        <Close fontSize="large" color="inherit" />
      </MuiButton>
      <motion.div
        initial={{ width: 150 }}
        animate={{
          width: 300
        }}
        exit={{
          width: 150,
          transition: { delay: 1, duration: 0.6 }
        }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};
export default SideBar;
