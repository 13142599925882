import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createNewProject,
  deleteProject,
  downloadSrt,
  duplicateProject,
  fetcheSnippets,
  getAnnotationProjectDetails,
  getAssociatedMynaProjects,
  getGTTSPlay,
  getMynaBackendDetails,
  getProjectFinalSnippets,
  getTargetSRT,
  setTargetSnippets,
  updateEditSnippets,
  updatePhonemeSnippets,
  updateProjectTitle,
  updateSplitMergeSnippets,
  updateStatus,
  uploadProjectDetailsv2,
  uploadVideo,
  uploadVideov2,
  verifyCTCTimeStamps,
  verifyEditSnippets
} from 'services/ProjectServices';
import {
  updateAnnotationModal,
  updateAnnotationProject,
  updateMynaBackendDetails
} from 'slices/ProjectSlice';
import { ProjectSnippetsType, SnippetType } from 'slices/ProjectSlice.types';
import {
  updateTargetSnippets as UpdateTargetSnippetsAction,
  updateProjectSnippet
} from 'slices/ProjectSnippetsSlice';
import { setStatus } from 'slices/StatusSlice';
import { getApisSlice } from 'store/api/slices/getApis/getApisSlice';
import { GET_API_TAGS } from 'store/api/slices/getApis/getApisTags';
import {
  ASR_TYPE,
  FinalSourceSnippetsType,
  LANGUAGE_TYPE,
  MODAL_LANG_TYPE,
  PROJECT_STATUS,
  PROJECT_STATUS_TYPES,
  ProjectDetails,
  SAMPLING_TYPE,
  SnippetVerifyResponseType,
  SuccessType,
  VerifyCTCReponse
} from 'types/Project';
import { formatErrorDetails } from 'utils/error';

export interface UseProjectType {
  createProject: (title: string, description: string, userId: string) => Promise<string>;
  isCreatingProject: boolean;
  updateTargetSnippets: (projectId: string, payload: SnippetType[]) => Promise<boolean>;
  isLoading: boolean;
  fetchAnnnotationProjectDetails: (project_id: string) => Promise<Record<string, string>>;
  fetchMynaBackendDetails: (project_id: string) => Promise<Record<string, string>>;
  updateProjectName: (projectId: string, title: string) => Promise<ProjectDetails>;
  projectDelete: (projectId: string) => Promise<SuccessType>;
  updateProjectDetails: (
    modalLang: MODAL_LANG_TYPE,
    srcLang: LANGUAGE_TYPE,
    project_id: string,
    samplingRate: SAMPLING_TYPE,
    responses: Array<boolean>,
    asrService?: ASR_TYPE
  ) => Promise<boolean>;
  verifyCTCSnippets: (projectId: string, payload: SnippetType[]) => Promise<VerifyCTCReponse>;
  uploadMedia: (payload: File, project_id: string) => Promise<boolean>;
  uploadMediav2: (project_id: string) => Promise<boolean>;
  downloadSRT: (projectId: string, snippets: SnippetType[]) => Promise<boolean>;
  fetchFinalSnippets: (annotation_project_id: string) => Promise<FinalSourceSnippetsType[]>;
  gttsPlay: (annotation_project_id: string, text: string) => Promise<string>;
  fetchAssociatedprojects: (projectId: string) => Promise<string[]>;
  isDownloading: boolean;
  isSaving: boolean;
  updateProjectStatus: (
    projectId: string,
    status: PROJECT_STATUS_TYPES,
    showToaster?: boolean
  ) => Promise<ProjectDetails>;
  projectDuplicate: (projectId: string) => Promise<ProjectDetails>;
  verifyEditedSnippets: (
    projectId: string,
    payload: SnippetType[]
  ) => Promise<SnippetVerifyResponseType>;
  fetchCurrentSnippets: (
    projectId: string,
    status: PROJECT_STATUS_TYPES,
    payload?: string
  ) => Promise<ProjectSnippetsType>;
  updateSnippets: (
    projectId: string,
    payload: SnippetType[],
    status: PROJECT_STATUS.SPLIT_MERGE_PROCESSED | PROJECT_STATUS.SPLIT_MERGE_PROCESSING
  ) => Promise<boolean>;
  updateEditedSnippets: (
    projectId: string,
    payload: SnippetType[],
    status: PROJECT_STATUS.EDIT_SNIPPETS_PROCESSED | PROJECT_STATUS.EDIT_SNIPPETS_PROCESSING
  ) => Promise<boolean>;
  fetchTargetSnippets: (annotation_project_id: string) => Promise<FinalSourceSnippetsType[]>;
  updatePhonemeSnippet: (
    projectId: string,
    payload: SnippetType[],
    status: PROJECT_STATUS.MFA_PROCESSED | PROJECT_STATUS.MFA_PROCESSING
  ) => Promise<boolean>;
  isAsrLoading: boolean;
  isVerifying: boolean;
  isUploading: boolean;
}

export const useProject = (): UseProjectType => {
  const dispatch = useDispatch();
  const [isCreatingProject, setIsCreatingProject] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isAsrLoading, setIsAsrLoading] = useState<boolean>(false);
  const [isVerifying, setIsVerifying] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isUploading, setIsUploading] = useState<boolean>(false);

  const createProject = useCallback(
    async (title: string, description: string, userId: string) => {
      try {
        setIsCreatingProject(true);
        const data = await createNewProject(title, description, userId);
        if (data) {
          return data.annotation_project_id;
        }
        return null;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      } finally {
        setIsCreatingProject(false);
      }
    },
    [dispatch]
  );

  const uploadMedia = async (payload: File, project_id: string) => {
    try {
      setIsUploading(true);
      const response = await uploadVideo(payload, project_id);
      if (response) {
        dispatch(setStatus({ type: 'success', message: 'Video Upload Success' }));
        return true;
      }
      return false;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    } finally {
      setIsUploading(false);
    }
  };

  const uploadMediav2: UseProjectType['uploadMediav2'] = async (project_id) => {
    try {
      setIsUploading(true);
      const response = await uploadVideov2(project_id);
      if (response) {
        dispatch(setStatus({ type: 'success', message: 'Video Upload Success' }));
        dispatch(
          getApisSlice.util.invalidateTags([
            {
              type: GET_API_TAGS.AnnotationProject,
              id: project_id
            }
          ])
        );
        return true;
      }
      return false;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));

      return null;
    } finally {
      setIsUploading(false);
    }
  };

  const fetchAnnnotationProjectDetails = useCallback(
    async (project_id: string) => {
      try {
        setIsLoading(true);
        const response = await getAnnotationProjectDetails(project_id);
        if (response) {
          dispatch(updateAnnotationProject(response));
          dispatch(updateAnnotationModal(true));
        }
        return response;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );
  const fetchMynaBackendDetails = useCallback(
    async (project_id: string) => {
      try {
        setIsLoading(true);
        const response = await getMynaBackendDetails(project_id);
        if (response) {
          dispatch(updateMynaBackendDetails(response));
          dispatch(updateAnnotationModal(true));
        }
        return response;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const updateProjectName = async (projectId: string, title: string) => {
    try {
      setIsLoading(true);
      const response = await updateProjectTitle(title, projectId);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    } finally {
      setIsLoading(false);
    }
  };
  const projectDelete = async (projectId: string) => {
    try {
      setIsLoading(true);
      const response = await deleteProject(projectId);
      dispatch(
        getApisSlice.util.invalidateTags([
          {
            type: GET_API_TAGS.AnnotationProject,
            id: projectId
          }
        ])
      );
      return response;
    } catch (err) {
      const error = err as AxiosError;

      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
    } finally {
      setIsLoading(false);
    }
  };
  const projectDuplicate = async (projectId: string) => {
    try {
      setIsLoading(true);
      const response = await duplicateProject(projectId);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
    } finally {
      setIsLoading(false);
    }
  };
  const fetchAssociatedprojects = async (projectId: string) => {
    try {
      setIsLoading(true);
      const response = await getAssociatedMynaProjects(projectId);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
    } finally {
      setIsLoading(false);
    }
  };
  const updateProjectDetails: UseProjectType['updateProjectDetails'] = async (
    modalLang: MODAL_LANG_TYPE,
    srcLang: LANGUAGE_TYPE,
    project_id: string,
    sampling_rate: SAMPLING_TYPE,
    responses: Array<boolean>,
    asrService?: ASR_TYPE
  ) => {
    try {
      setIsLoading(true);
      const data = await uploadProjectDetailsv2(
        modalLang,
        srcLang,
        sampling_rate,
        project_id,
        responses,
        asrService
      );
      if (data) {
        dispatch(setStatus({ type: 'success', message: 'Upload Success' }));
        return true;
      }
      return null;
    } catch (err) {
      console.log({ err });
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTargetSnippets = useCallback(
    async (annotation_project_id: string) => {
      try {
        setIsAsrLoading(true);
        const response = await getTargetSRT(annotation_project_id);
        dispatch(
          UpdateTargetSnippetsAction({
            projectId: annotation_project_id,
            snippets: response
          })
        );

        return response;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      } finally {
        setIsAsrLoading(false);
      }
    },
    [dispatch]
  );
  const fetchFinalSnippets = useCallback(
    async (annotation_project_id: string) => {
      try {
        setIsAsrLoading(true);
        const response = await getProjectFinalSnippets(annotation_project_id);
        if ('snippets' in response) {
          dispatch(
            updateProjectSnippet({
              projectId: annotation_project_id,
              snippets: response.snippets,
              status: PROJECT_STATUS.COMPLETED,
              target_srt_enabled: response?.target_srt_enabled
            })
          );
        }
        return response.snippets;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      } finally {
        setIsAsrLoading(false);
      }
    },
    [dispatch]
  );
  const fetchCurrentSnippets = useCallback(
    async (projectId: string, status: PROJECT_STATUS_TYPES, payload?: string) => {
      try {
        setIsAsrLoading(true);
        const response = await fetcheSnippets(projectId, status, payload);
        if (response) dispatch(updateProjectSnippet({ projectId, snippets: response.snippets }));
        return response;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      } finally {
        setIsAsrLoading(false);
      }
    },
    [dispatch]
  );
  const downloadSRT = async (projectId: string, snippets: SnippetType[]) => {
    try {
      setIsDownloading(true);
      const response = await downloadSrt(projectId, snippets);

      if (response) {
        const csvBlob = new File([response as BlobPart], 'srt');
        const csvUrl = URL.createObjectURL(csvBlob);
        const link = document.createElement('a');
        link.href = csvUrl;
        link.setAttribute('download', 'data.srt');
        document.body.appendChild(link);
        link.click();
        dispatch(setStatus({ type: 'success', message: 'SRT Downloaded' }));
      }
      return false;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    } finally {
      setIsDownloading(false);
    }
  };

  const gttsPlay = async (annotation_project_id: string, text: string) => {
    try {
      const response = await getGTTSPlay(annotation_project_id, text);
      return response;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    }
  };
  const updateSnippets = async (
    projectId: string,
    payload: SnippetType[],
    status: PROJECT_STATUS.SPLIT_MERGE_PROCESSED | PROJECT_STATUS.SPLIT_MERGE_PROCESSING
  ) => {
    try {
      const response = await updateSplitMergeSnippets(projectId, payload, status);
      if (response) return true;
      return false;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    }
  };

  const updateTargetSnippets = async (projectId: string, payload: SnippetType[]) => {
    try {
      const response = await setTargetSnippets(projectId, payload);

      if ('snippets' in response.data) {
        dispatch(
          UpdateTargetSnippetsAction({
            projectId,
            snippets: response.data.snippets
          })
        );
      }
      if (response) return true;
      return false;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    }
  };

  const updateEditedSnippets = useCallback(
    async (
      projectId: string,
      payload: SnippetType[],
      status: PROJECT_STATUS.EDIT_SNIPPETS_PROCESSED | PROJECT_STATUS.EDIT_SNIPPETS_PROCESSING
    ) => {
      try {
        const response = await updateEditSnippets(projectId, payload, status);
        if (response) return true;
        return false;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      }
    },
    [dispatch]
  );
  const updatePhonemeSnippet = useCallback(
    async (
      projectId: string,
      payload: SnippetType[],
      status: PROJECT_STATUS.MFA_PROCESSED | PROJECT_STATUS.MFA_PROCESSING
    ) => {
      try {
        setIsSaving(true);
        const response = await updatePhonemeSnippets(projectId, payload, status);
        if (response) return true;
        return false;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      } finally {
        setIsSaving(false);
      }
    },
    [dispatch]
  );

  const updateProjectStatus = useCallback(
    async (projectId: string, status: PROJECT_STATUS_TYPES, showToaster?: boolean) => {
      try {
        setIsLoading(true);
        const response = await updateStatus(projectId, status);
        if (showToaster) {
          if (response) {
            dispatch(setStatus({ type: 'success', message: 'Completed' }));
          }
        }
        return response;
      } catch (err) {
        const error = err as AxiosError;
        const { message } = formatErrorDetails(error);
        dispatch(setStatus({ type: 'error', message: message }));
        return null;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const verifyEditedSnippets = async (projectId: string, payload: SnippetType[]) => {
    try {
      setIsVerifying(true);
      const response = await verifyEditSnippets(projectId, payload);
      if (response) return response;
      return null;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    } finally {
      setIsVerifying(false);
    }
  };
  const verifyCTCSnippets = async (projectId: string, payload: SnippetType[]) => {
    try {
      setIsVerifying(true);
      const response = await verifyCTCTimeStamps(projectId, payload);
      if (response) return response;
      return null;
    } catch (err) {
      const error = err as AxiosError;
      const { message } = formatErrorDetails(error);
      dispatch(setStatus({ type: 'error', message: message }));
      return null;
    } finally {
      setIsVerifying(false);
    }
  };

  return {
    uploadMediav2,
    createProject,
    fetchAssociatedprojects,
    isCreatingProject,
    downloadSRT,
    verifyCTCSnippets,
    isLoading,
    updateProjectName,
    isSaving,
    projectDelete,
    projectDuplicate,
    fetchCurrentSnippets,
    isAsrLoading,
    updateSnippets,
    verifyEditedSnippets,
    fetchFinalSnippets,
    isVerifying,
    updatePhonemeSnippet,
    isDownloading,
    updateProjectDetails,
    updateEditedSnippets,
    updateProjectStatus,
    uploadMedia,
    fetchTargetSnippets,
    gttsPlay,
    isUploading,
    updateTargetSnippets,
    fetchAnnnotationProjectDetails,
    fetchMynaBackendDetails
  };
};
