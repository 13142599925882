import { AlertColor } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';

// we can add project details here

interface StatusSlice {
  status: {
    type: AlertColor;
    message: string;
  };
  loading: boolean;
}

const initialState: StatusSlice = {
  status: {
    type: 'success',
    message: ''
  },
  loading: false
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      state.status = { ...state.status, ...action.payload };
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    }
  }
});

export const { setStatus, setLoading } = statusSlice.actions;

export default statusSlice.reducer;
