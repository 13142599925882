import { createSlice } from '@reduxjs/toolkit';

interface UserDetailsType {
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  role: string;
  updated_at: string;
  user_id: string;
  phone_number: string;
  user_type?: 'internal_user' | 'external_user';
  permissions: {
    view_annotations: boolean;
    edit_annotations: boolean;
    view_trainings: boolean;
    edit_trainings: boolean;
    view_projects: boolean;
    edit_projects: boolean;
  };
}
interface UserState {
  UserDetails: UserDetailsType;
}

const initialState: UserState = {
  UserDetails: null
};

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.UserDetails = action.payload;
    }
  }
});

export const { setUserDetails } = UserSlice.actions;

export default UserSlice.reducer;
