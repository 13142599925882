import { RouteGuard } from 'components/App/RouteGuard';
import ErrorBoundary from 'components/ErrorBoundary';
import Layout from 'components/Layout/Layout';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { Provider } from 'react-redux';

import { AppStore } from '../store/AppStore';

import './styles.css';
import AppContent from './AppContent';

function CustomApp({ Component, pageProps }: AppProps) {
  return (
    <Provider store={AppStore}>
      <Head>
        <link rel="shortcut icon" href="/assets/favicon.ico" />
        <title>Training App</title>
      </Head>
      <main className="app">
        <RouteGuard>
          <Layout>
            <ErrorBoundary>
              <AppContent Component={Component} pageProps={pageProps} />
            </ErrorBoundary>
          </Layout>
        </RouteGuard>
      </main>
    </Provider>
  );
}

export default CustomApp;
