import { createSlice } from '@reduxjs/toolkit';
import _filter from 'lodash/filter';

import { ProjectSnippetsType, SnippetType } from './ProjectSlice.types';

const initialState: {
  projectSnippets: ProjectSnippetsType[];
  targetSnippets: ProjectSnippetsType[];
  newSnippetDetails: SnippetType;
} = {
  projectSnippets: [],
  targetSnippets: [],
  newSnippetDetails: null
};

export const SnippetsSlice = createSlice({
  name: 'Snippets',
  initialState,
  reducers: {
    updateNewSnippet: (state, action) => {
      state.newSnippetDetails = action.payload;
    },
    updateProjectSnippet: (state, action) => {
      state.projectSnippets = [
        ..._filter(state.projectSnippets, (snips) => snips.projectId !== action.payload.projectId),
        action.payload
      ];
    },
    updateTargetSnippets: (state, action) => {
      state.targetSnippets = [
        ..._filter(state.targetSnippets, (snips) => snips.projectId !== action.payload.projectId),
        action.payload
      ];
    }
  }
});

export const { updateProjectSnippet, updateTargetSnippets, updateNewSnippet } =
  SnippetsSlice.actions;

export default SnippetsSlice.reducer;
