import { createApi } from '@reduxjs/toolkit/query/react';

import { axiosBaseQuery } from './baseQuery';

export const apiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseUrl: process.env.NEXT_PUBLIC_API_ENDPOINT
  }),
  endpoints() {
    // inject endpoint later
    return {};
  }
});
