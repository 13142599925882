import FullscreenLoader from '@libs/atoms/fullscreen-loader';
import { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { RoutePath } from 'routes';
import CustomerAuth from 'services/auth';
import { setUserDetails } from 'slices/UserSlice';
import { useLazyGetUserDetailQuery } from 'store/api/slices/getApis/getApisSlice';

function AppContent({ Component, pageProps }: Pick<AppProps, 'Component' | 'pageProps'>) {
  const dispatch = useDispatch();
  const router = useRouter();
  const { push } = router;
  const [loading, setLoading] = useState(false);
  const [getUserDetails, _, __] = useLazyGetUserDetailQuery();

  useEffect(() => {
    if (CustomerAuth.isAuthenticated) {
      setLoading(true);
      getUserDetails(undefined, true).then(({ data: userInfo }) => {
        dispatch(setUserDetails(userInfo));
        setLoading(false);
      });
    } else {
      if (
        router.asPath !== RoutePath.login &&
        router.asPath !== '/verify_email' &&
        router.asPath !== '/reset_password'
      ) {
        push('/login');
      }
    }
  }, []);

  return loading ? <FullscreenLoader /> : <Component {...pageProps} />;
}

export default AppContent;
